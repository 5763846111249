Vue.component("bs-selectpicker", {
    props: ["datas"],
    template: `<select>
  <option
    v-for="(option, index) in datas"
    :key="index"
    :value="option.value"
    :selected="option.selected">
    {{ option.label }}
  </option>
</select>`,
    mounted() {
        const $selectpicker = $(this.$el);
        $selectpicker.selectpicker()
            .on("changed.bs.select", () => this.$emit("select-change", $selectpicker.val()));
        this.$emit("select-change", $selectpicker.val());
    },
    updated() {
        const $selectpicker = $(this.$el);
        $selectpicker.selectpicker("refresh");
        this.$emit("select-change", $selectpicker.val());
    },
    destroyed() {
        const $selectpicker = $(this.$el);
        $selectpicker.off()
            .selectpicker("destroy");
    }
});

Vue.component("valid-msg",
    {
        props: ["vaild", "msg"],
        template: `<div><span class="text-danger" v-if="!vaild">{{msg}}</span></div>`
    });

/**
   * 百分比的formater
   */
var percentFormater = new Intl.NumberFormat("en-US",
    {
        style: "percent"
    });
/**
* 小數的formater
*/
var fix2Formater = new Intl.NumberFormat("en-US",
    {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    });
Vue.filter("percent",
    function (value) {
        if (value === null || isNaN(value))
            return "--";
        return percentFormater.format((value));
    });
Vue.filter("noSignPercent",
    function (value) {
        if (value === null || isNaN(value))
            return "--";
        return (value * 100).toFixed(0);
    });
Vue.filter("fix2",
    function (value) {
        if (value === null || isNaN(value))
            return "--";
        return fix2Formater.format((value));
    });

Vue.filter("moment",
    function (value, format) {
        const m = moment(value);
        return m._isValid ? m.format(format) : "--";
    });
// Vue.component(VueQrcode.name, VueQrcode);

const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.';
Vue.config.warnHandler = function (msg, vm, trace) {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
}

